import React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import Video from '../Video'
import { BackgroundImage, FixedImage } from '../images'
import { renderBlocks } from './'
import { lang } from '../../config'

const backgroundPresets = {
  'Featured Banner': '#fff',
  'Gray Gradient': 'linear-gradient(to bottom, #e4e5e6, #ffffff)',
}

const TextColumns = ({ fields }) => {
  let {
    image,
    heading,
    description,
    backgroundPreset,
    backgroundImage,
    videoUrl,
    videoPosition,
  } = fields
  image = image && image[lang].fields
  heading = heading && heading[lang]
  description = description[lang]
  backgroundPreset = backgroundPreset && backgroundPreset[lang][0]
  backgroundImage = backgroundImage && backgroundImage[lang].fields
  videoUrl = videoUrl && videoUrl[lang]
  videoPosition = (videoPosition && videoPosition[lang]) || 'Above'

  return (
    <Wrapper className="full-bleed" background={backgroundPreset}>
      {videoUrl && videoPosition === 'Above' && (
        <VideoContainer className="video-above">
          <Video url={videoUrl} />
        </VideoContainer>
      )}
      <StyledContainer className="container">
        <BackgroundImage fields={backgroundImage} />
        {image && backgroundPreset === 'Featured Banner' && (
          <FeaturedBannerImage>
            <FixedImage fields={image} />
          </FeaturedBannerImage>
        )}
        <Content className="content">
          {backgroundPreset === 'Featured Banner' && (
            <BackgroundRectangleArcBefore />
          )}
          <div>
            {image && backgroundPreset !== 'Featured Banner' && (
              <Image>
                <FixedImage fields={image} />
              </Image>
            )}
            {heading && <Heading>{heading}</Heading>}
          </div>
          <Description>{renderBlocks(description)}</Description>
          {backgroundPreset === 'Featured Banner' && (
            <BackgroundRectangleArcAfter />
          )}
        </Content>
      </StyledContainer>
      {videoUrl && videoPosition === 'Below' && (
        <VideoContainer className="video-below">
          <Video url={videoUrl} />
        </VideoContainer>
      )}
    </Wrapper>
  )
}

export default TextColumns

const Wrapper = styled.section`
  background: ${props =>
    props.background ? backgroundPresets[props.background] : 'transparent'};
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 64px 0;
  padding-bottom: ${props =>
    props.background && props.background === 'Featured Banner'
      ? '100px'
      : '54px'};
  display: block;

  & .container {
    flex-wrap: ${props =>
      props.background && props.background === 'Featured Banner'
        ? 'wrap'
        : 'inherit'};
  }

  & .content {
    background: ${props =>
      props.background && props.background === 'Featured Banner'
        ? '#fff'
        : 'transparent'};
    position: relative;
    margin: ${props =>
      props.background && props.background === 'Featured Banner'
        ? '50px auto'
        : '0 auto'};
    padding: ${props =>
      props.background && props.background === 'Featured Banner'
        ? '100px 70px'
        : '0'};
    padding-bottom: ${props =>
      props.background && props.background === 'Featured Banner'
        ? '40px'
        : '0'};
    position: relative;
  }

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  @media screen and (max-width: 899px) {
    padding: 45px 0;

    & .content {
      margin: ${props =>
        props.background && props.background === 'Featured Banner'
          ? '70px auto'
          : '0 auto'};
      padding: ${props =>
        props.background && props.background === 'Featured Banner'
          ? '10px 28px'
          : '0'};
      padding-bottom: ${props =>
        props.background && props.background === 'Featured Banner'
          ? '16px'
          : '0'};
    }
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 2em;

  * {
    z-index: 1;
  }

  @media screen and (max-width: 899px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const VideoContainer = styled(StyledContainer)`
  @media screen and (min-width: 900px) {
    & > .video {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 899px) {
    &.video-above {
      margin-bottom: 15px;
    }

    &.video-above {
      margin-top: 15px;
    }
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: -20px;

  @media screen and (max-width: 899px) {
    display: block;
  }
`

const Heading = styled.h2`
  margin: 0 0 20px;
  font-size: 47px;
  line-height: 50px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  padding-right: 30px;
  max-width: 370px;

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
  }
`

const Description = styled.div`
  font-size: 23px;
  line-height: 33px;
  flex-basis: 770px;
  margin-left: 30px;

  & > p {
    margin-top: 15px;
    margin-bottom: 15px;

    &:empty:last-child {
      margin-top: 0;
    }
  }

  & > p:first-of-type {
    margin-top: 0;
  }

  & > h5,
  & > h6 {
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
  }

  & > h5 {
    font-size: 21px;
    line-height: 28px;
    text-transform: uppercase;
  }

  & > h6 {
    font-size: 18px;
    line-height: 28px;
  }

  & > hr {
    border: 1px solid rgba(3, 45, 108, 0.31);
    margin: 50px 0;
  }

  & a[data-type='button'] {
    border: 2px solid ${props => props.theme.colors.secondary};
    border-radius: 5px 25px 25px 5px;
    padding: 8px 20px;
    margin-top: 13px;
    display: inline-block;
    font-weight: bold;
    font-size: 23px;
    line-height: 33px;
    display: inline-flex;
    align-items: center;

    &,
    &:hover,
    &:focus,
    &:visited,
    &:active {
      color: ${props => props.theme.colors.secondary};
    }

    &:hover,
    &:focus {
      background: ${props => props.theme.colors.secondary};
      color: #fff;
    }

    &::after {
      content: '';
      background: ${props => props.theme.colors.secondary};
      mask-image: url('/images/arrow-icon.svg');
      display: inline-block;
      width: 9.53px;
      height: 16.67px;
      transition: 0.3s all;
      margin-left: 20px;
    }

    &:hover::after {
      background: #fff;
      transition: 0.3s all;
    }
  }

  @media screen and (max-width: 899px) {
    margin-left: 0;
  }
`

const Image = styled.div`
  margin-bottom: 17px;

  @media screen and (max-width: 899px) {
    text-align: center;
  }
`

const FeaturedBannerImage = styled.div`
  flex-basis: 100%;
  text-align: center;
  margin-bottom: -20px;

  @media screen and (max-width: 899px) {
    & .gatsby-image-wrapper {
      max-width: 100px;
      max-height: 110.5px;

      img {
        object-fit: contain;
      }
    }
  }
`

const BackgroundRectangleArc = styled.div`
  background: url('/images/rectangle-arc.svg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px;
  width: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
`

const BackgroundRectangleArcBefore = styled(BackgroundRectangleArc)`
  top: -50px;
  background-position: top center;
`

const BackgroundRectangleArcAfter = styled(BackgroundRectangleArc)`
  bottom: -50px;
  background-position: bottom center;
`
