import React from 'react'
import styled from '@emotion/styled'

const ArrowIcon = ({
  color = 'inherit',
  width = '12px',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 11.563 20.223"
    {...props}
  >
    <path
      id="Icon_ionic-ios-arrow-forward"
      data-name="Icon ionic-ios-arrow-forward"
      d="M19.324,16.3,11.671,8.657a1.439,1.439,0,0,1,0-2.041,1.457,1.457,0,0,1,2.047,0l8.67,8.664a1.443,1.443,0,0,1,.042,1.993L13.724,26a1.445,1.445,0,0,1-2.047-2.041Z"
      transform="translate(-11.246 -6.196)"
      fill={color}
    />
  </SVG>
)

export default ArrowIcon

const SVG = styled.svg`
  padding: 0;
`
