import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Banner from './Banner'
import Button from './Button'
import Cards from './Cards'
import ImageAndText from './ImageAndText'
import ContactInfo from './ContactInfo'
import ContactForm from './ContactForm'
import Hero from './Hero'
import LatestTweet from './LatestTweet'
import Products from './Products'
import StaffMembers from './StaffMembers'
import TextColumns from './TextColumns'
import { FixedImage } from '../images'
import styled from '@emotion/styled'

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <BoldSpan>{text}</BoldSpan>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const fields = node.data.target.fields
      const blockType =
        node.data.target.sys.contentType &&
        node.data.target.sys.contentType.sys &&
        node.data.target.sys.contentType.sys.id
          ? node.data.target.sys.contentType.sys.id
          : null
      switch (blockType) {
        case 'blockBanner':
          return <Banner fields={fields} />
        case 'blockButton':
          return <Button data={fields} />
        case 'blockCards':
          return <Cards fields={fields} />
        case 'blockColumns':
          return <ImageAndText fields={fields} />
        case 'blockContact':
          return <ContactInfo fields={fields} />
        case 'blockContactForm':
          return <ContactForm fields={fields} />
        case 'blockHero':
          return <Hero fields={fields} />
        case 'blockLatestTweet':
          return <LatestTweet fields={fields} />
        case 'blockProducts':
          return <Products fields={fields} />
        case 'blockStaffMembers':
          return <StaffMembers fields={fields} />
        case 'blockTextColumns':
          return <TextColumns fields={fields} />
        default:
          return ''
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      const fields = node.data.target.fields
      return <FixedImage fields={fields} />
    },
  },
}

export const renderBlocks = data => {
  return documentToReactComponents(data, options)
}

const BoldSpan = styled.span`
  font-weight: bold;
`
