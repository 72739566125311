import React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import ContactForm from '../forms/ContactForm'
import { BackgroundImage } from '../images'
import { lang } from '../../config'
import { backgroundPresets } from './_utils'

const ContactFormBlock = ({ fields }) => {
  let { heading } = fields
  heading = heading && heading[lang]

  const backgroundPreset = 'Blue'

  return (
    <Wrapper className="full-bleed" background={backgroundPreset}>
      <StyledContainer className="container">
        <BackgroundImage
          fields={
            backgroundPresets[backgroundPreset] &&
            backgroundPresets[backgroundPreset].backgroundImage
              ? backgroundPresets[backgroundPreset].backgroundImage
              : null
          }
        />
        <Content className="content">
          {heading && <Heading>{heading}</Heading>}
          <ContactForm />
        </Content>
      </StyledContainer>
    </Wrapper>
  )
}

export default ContactFormBlock

const Heading = styled.h2`
  margin: 0 0 20px;
  font-size: 33px;
  line-height: 80px;
  font-weight: bold;
  padding-right: 30px;
  margin-top: -22px;
  margin-bottom: 0;

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
  }
`

const Wrapper = styled.section`
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 72px 0;
  display: block;

  ${Heading} {
    color: ${props =>
      props.background && backgroundPresets[props.background].headingColor
        ? backgroundPresets[props.background].headingColor
        : 'inherit'};
  }

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  @media screen and (max-width: 899px) {
    padding-bottom: 55px;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 2em;
  max-width: 952px;

  * {
    z-index: 1;
  }

  @media screen and (max-width: 899px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Content = styled.div`
  width: 100%;
`
