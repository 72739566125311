import React from 'react'
import styled from '@emotion/styled'
import { FluidImage } from './'

const BackgroundImage = ({ fields }) => {
  if (!fields) return ''

  return (
    <BackgroundImageWrapper className="bg">
      <FluidImage fields={fields} />
    </BackgroundImageWrapper>
  )
}

export default BackgroundImage

const BackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  & .gatsby-image-wrapper {
    height: 100%;
  }
`
