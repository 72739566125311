import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import { lang } from '../../config'

const LatestTweetBlock = ({ fields }) => {
  let { heading } = fields
  heading = heading && heading[lang]

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }, [])

  return (
    <Wrapper className="full-bleed">
      <StyledContainer className="container">
        <Content className="content">
          {heading && <Heading>{heading}</Heading>}
          {!loading && (
            <Tweet>
              <a
                className="twitter-timeline"
                href="https://twitter.com/GTFedPubs"
                data-tweet-limit="1"
                data-width="980"
                data-dnt="true"
                data-chrome="noheader, noborders"
              >
                Latest Tweet by GTFedPubs
              </a>
            </Tweet>
          )}
        </Content>
      </StyledContainer>
    </Wrapper>
  )
}

export default LatestTweetBlock

const Wrapper = styled.section`
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 72px 0;
  display: block;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  @media screen and (max-width: 899px) {
    padding-bottom: 55px;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 2em;

  * {
    z-index: 1;
  }

  @media screen and (max-width: 899px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Content = styled.div`
  width: 100%;
`

const Heading = styled.h2`
  margin: 0 0 20px;
  font-size: 47px;
  line-height: 60px;
  font-weight: bold;
  padding-right: 30px;
  margin-top: -22px;
  margin-bottom: 0;

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
  }
`

const Tweet = styled.div`
  text-align: center;
  border-radius: 10px;
  border: 1px solid #bbbbbb;
  display: block;
  max-width: 1000px;
  margin-top: 30px;
  padding: 10px;
  padding-bottom: 0;

  .timeline-Header {
    display: none !important;
  }
`
