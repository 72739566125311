import React from 'react'
import styled from '@emotion/styled'

const LoadingIcon = ({
  color = '#fff',
  width = '36px',
  height = '36px',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="loading-icon"
    {...props}
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke={color}
      strokeWidth="10"
      r="36"
      strokeDasharray="169.64600329384882 58.548667764616276"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="2s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      ></animateTransform>
    </circle>
  </SVG>
)

export default LoadingIcon

const SVG = styled.svg`
  padding: 0;
  margin: auto;
  background: none;
  display: block;
  shape-rendering: auto;
`
