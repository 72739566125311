import React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import Button from './Button'
import { BackgroundImage, FixedImage } from '../images'
import { lang } from '../../config'

const heroBackgroundImage = {
  title: { 'en-US': 'Hero BG' },
  file: {
    'en-US': {
      url: '/images/bg-hero.jpg',
      contentType: 'image/jpeg',
      fileName: 'bg-hero.jpg',
      details: { image: { width: 1920, height: 992 }, size: 14165 },
    },
  },
}

const Hero = ({ fields }) => {
  let {
    headline,
    subhead,
    description,
    buttonMain,
    buttonSecondary,
    heroImage,
    heroImageAnimated,
    columns,
  } = fields
  headline = headline[lang]
  subhead = subhead && subhead[lang]
  description = description && description[lang]
  buttonMain = buttonMain && buttonMain[lang].fields
  buttonSecondary = buttonSecondary && buttonSecondary[lang].fields
  heroImage = heroImage && heroImage[lang].fields
  heroImageAnimated = heroImageAnimated && heroImageAnimated[lang]
  columns = columns && columns[lang][0]

  let contentColumnWidth
  let heroImageWidth
  if (columns && columns.includes('-')) {
    let columnWidths = columns.split('-')
    contentColumnWidth = columnWidths[0]
    heroImageWidth = columnWidths[1]
  }

  const hasHeroImage = !!heroImage

  return (
    <Wrapper className="full-bleed">
      <StyledContainer>
        <BackgroundImage fields={heroBackgroundImage} />
        <Content
          width={contentColumnWidth && `${contentColumnWidth}%`}
          centerOnMobile={!hasHeroImage}
        >
          <Headline>{headline}</Headline>
          {subhead && <Subhead>{subhead}</Subhead>}
          {description && (
            <Description className="description">{description}</Description>
          )}
          {(buttonMain || buttonSecondary) && (
            <ActionButtons>
              <Button type="primary" data={buttonMain} />
              <SecondaryLinkButton data={buttonSecondary} />
            </ActionButtons>
          )}
        </Content>
        {hasHeroImage && (
          <HeroImage width={heroImageWidth && `${heroImageWidth}%`}>
            <FixedImage fields={heroImage} animated={heroImageAnimated} />
          </HeroImage>
        )}
      </StyledContainer>
    </Wrapper>
  )
}

export default Hero

const Wrapper = styled.section`
  position: relative;
  min-height: 300px;
  height: auto;
  overflow: visible;
  padding: 64px 0;
  display: block;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;

  * {
    z-index: 1;
  }

  @media screen and (max-width: 899px) {
    flex-direction: column-reverse;
    padding-bottom: 0;
  }
`

const Content = styled.div`
  margin-right: 30px;
  margin-top: 36px;
  flex-basis: ${props => props.width};
  flex-grow: ${props => (props.width ? 0 : 1)};
  flex-shrink: ${props => (props.width ? 0 : 1)};

  @media screen and (max-width: 899px) {
    margin-right: 0;
    margin-top: 6px;
    text-align: ${props => (props.centerOnMobile ? 'center' : 'inherit')};
  }
`

const Headline = styled.h1`
  margin: 0 0 20px;
  font-size: 66px;
  line-height: 80px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};

  .page-home & {
    padding-right: 52px;
  }

  @media screen and (max-width: 899px) {
    font-size: 40px;
    line-height: 50px;

    .page-home & {
      padding-right: 0;
    }
  }
`

const Subhead = styled.h2`
  font-size: 33px;
  line-height: 38px;
  max-width: 720px;

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
  }
`

const Description = styled.p`
  margin: 20px 0 0;
  font-size: 23px;
  line-height: 38px;
`

const ActionButtons = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 899px) {
    display: block;
    margin-bottom: 0;
  }
`

const LinkButton = styled(Button)`
  text-decoration: none;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
`

const SecondaryLinkButton = styled(LinkButton)`
  &,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: ${props => props.theme.colors.primary};
  }

  svg {
    margin-left: 11px;
    width: 14px;
    fill: ${props => props.theme.colors.primary};
  }

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.secondary};

    svg {
      fill: ${props => props.theme.colors.secondary};
      transition: 0.3s all;
    }
  }

  &,
  & + .dropdown {
    margin-left: 30px;
  }

  & + .dropdown .dropdown-contents {
    p {
      margin: 0;
    }

    li {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    li a {
      font-weight: 700;
      font-size: 20px;
      line-height: 31px;
      position: relative;

      &::after {
        content: '';
        height: 3px;
        width: 100%;
        background: ${props => props.theme.colors.secondary};
        display: inline-block;
        margin-top: -3px;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: scaleX(0);
        transition: 0.3s all;
        transform-origin: left;
      }

      &,
      &:hover,
      &:focus,
      &:visited,
      &:active {
        color: ${props => props.theme.colors.primary};
      }

      &:hover::after,
      &:focus::after {
        transform: scaleX(1);
        transition: 0.3s all;
      }
    }
  }

  @media screen and (max-width: 899px) {
    margin-top: 30px;
    display: block;
  }
`

const HeroImage = styled.div`
  flex-basis: ${props => props.width};
  flex-grow: ${props => (props.width ? 0 : 1)};
  flex-shrink: ${props => (props.width ? 0 : 1)};
  text-align: right;

  @media screen and (max-width: 899px) {
    & .gatsby-image-wrapper {
      max-width: calc(100vw - 74px);

      img {
        position: relative !important;
        object-fit: contain !important;
      }
    }
  }
`
