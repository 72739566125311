import React from 'react'
import styled from '@emotion/styled'
import Modal from '../partials/Modal'
import { FixedImage } from '../images'
import { LinkedInIcon, PlusIcon } from '../../icons'
import { renderBlocks } from './'
import { lang } from '../../config'

const MaybeWrapWithModal = ({ activateModal, children, modalContents }) => {
  if (activateModal) {
    return (
      <BioModal
        activator={({ setShow }) => (
          <ToggleWrapper
            className="toggle-wrapper"
            onClick={() => setShow(true)}
          >
            {children}
            <MoreInfoButton>
              <MoreInfoIcon>
                <StyledPlusIcon />
              </MoreInfoIcon>
              More Info
            </MoreInfoButton>
          </ToggleWrapper>
        )}
        showClose
        maxWidth="700px"
      >
        {modalContents}
      </BioModal>
    )
  }

  return children
}

const BioModal = styled(Modal)`
  border-radius: 10px;

  .modal-body {
    background: #707070;
    padding: 32px;

    p {
      color: #fff;
    }
  }
`

const ToggleWrapper = styled.div`
  position: relative;
  cursor: pointer;
`

const MoreInfoButton = styled.div`
  max-width: 66px;
  text-align: center;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 23px;
  position: absolute;
  bottom: -20px;
  right: 0;

  @media screen and (max-width: 899px) {
    display: none;
  }
`

const MoreInfoIcon = styled.div`
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 66px;
  width: 66px;
  border: 3px solid #032d6c;
  margin-bottom: 6px;
`

const StyledPlusIcon = styled(PlusIcon)`
  & path {
    fill: #032d6c;
    transition: 0.1s all;
  }

  .toggle-wrapper:hover & path,
  .toggle-wrapper:focus & path {
    fill: ${props => props.theme.colors.secondary};
    transition: 0.1s all;
  }
`

const StaffMemberModalContents = ({ image, name, title, bio }) => {
  return (
    <>
      <ModalHeader>
        {image && (
          <Image className="headshot">
            <FixedImage fields={image.fields} />
          </Image>
        )}
        <Info>
          {name && <Name>{name}</Name>}
          {title && <Title>{title}</Title>}
        </Info>
      </ModalHeader>
      <Description>{renderBlocks(bio)}</Description>
    </>
  )
}

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .headshot {
    flex-basis: 210px;
    max-width: 210px;
  }

  @media screen and (max-width: 899px) {
    display: block;

    .headshot {
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }
`

const Info = styled.div`
  padding-left: 77px;

  * {
    color: #fff;
    margin-top: 0;
  }

  @media screen and (max-width: 899px) {
    padding-left: 0;
    text-align: center;
  }
`

const Description = styled.div`
  font-size: 20px;
  line-height: 33px;

  & p:last-child {
    margin-bottom: 0;
  }
`

const StaffMember = ({ data }) => {
  const { fields } = data
  let { image, name, title, bio, linkedIn } = fields
  image = image && image[lang]
  name = name && name[lang]
  title = title && title[lang]
  bio = bio && bio[lang]
  linkedIn = linkedIn && linkedIn[lang]

  const imageSrc =
    image &&
    image.fields &&
    image.fields.file &&
    image.fields.file[lang] &&
    image.fields.file[lang].url

  return (
    <StaffMemberContainer>
      <StaffMemberContent>
        <MaybeWrapWithModal
          activateModal={bio}
          modalContents={
            <StaffMemberModalContents
              image={image}
              name={name}
              title={title}
              bio={bio}
            />
          }
        >
          {image && imageSrc && (
            <Image className="headshot">
              <img src={imageSrc} alt={name} />
            </Image>
          )}
        </MaybeWrapWithModal>

        {name && <Name>{name}</Name>}
        {title && <Title>{title}</Title>}
        {linkedIn && (
          <LinkedInButton href={linkedIn} title={`${name} on LinkedIn`}>
            <LinkedInIcon color="#fff" width="33px" />
          </LinkedInButton>
        )}
      </StaffMemberContent>
    </StaffMemberContainer>
  )
}

export default StaffMember

const StaffMemberContainer = styled.div`
  margin: 0 -50px;
`

const StaffMemberContent = styled.div`
  width: 100%;
  max-width: 330px;
  margin: 0 auto;
  padding: 50px 15px;

  @media screen and (max-width: 899px) {
    padding: 10px 30px;
  }
`

const Image = styled.div`
  position: relative;
  width: 100%;
  clip-path: circle(50% at 50% 50%);
  background: #032d6c;

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    background: #032d6c url('/images/water-mark-logo.svg') no-repeat center
      center;
    background-size: 160%;
  }

  .toggle-wrapper:hover &::before {
    background-image: url('/images/water-mark-logo-hover.svg');
    transition: 0.1s background-image;
  }

  & > .gatsby-image-wrapper,
  & > img {
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    position: absolute !important;
    width: calc(100% + 8px) !important;
    height: calc(100% + 8px) !important;
  }
`

const Name = styled.div`
  margin-top: 20px;
  font-size: 33px;
  line-height: 52px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
`

const Title = styled.div`
  font-size: 20px;
  line-height: 33px;
`

const LinkedInButton = styled.a`
  width: 47px;
  height: 47px;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-top: 20px;

  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    background: ${props => props.theme.colors.primary};
    color: #fff;
  }

  &:hover,
  &:focus {
    background: ${props => props.theme.colors.secondary};
  }
`
