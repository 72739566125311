import React from 'react'
import styled from '@emotion/styled'
import { FixedImage } from '../images'
import { renderBlocks } from './'
import { lang } from '../../config'

const Card = ({ data }) => {
  const { fields } = data
  let { image, title, description, alignment } = fields
  image = image && image[lang]
  title = title && title[lang]
  description = description && description[lang]
  alignment = alignment && alignment[lang]

  return (
    <CardContainer alignment={alignment}>
      {image && (
        <Image>
          <FixedImage fields={image.fields} />
        </Image>
      )}
      {title && <Title>{title}</Title>}
      {description && <Description>{renderBlocks(description)}</Description>}
    </CardContainer>
  )
}

export default Card

const CardContainer = styled.div`
  border: 1px solid #707070;
  border-radius: 11px;
  padding: 38px;
  margin: 22px;
  background: #fff;

  @media screen and (max-width: 899px) {
    padding: 42px 58px;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    .page-home & {
      padding: 0;
      border: none;
    }

    .page-solutions & {
      padding: 37px 27px;
    }
  }
`

const Image = styled.div`
  width: 100%;
  text-align: center;
  overflow: hidden;
  margin-bottom: 20px;

  & > .gatsby-image-wrapper {
    max-height: 180px !important;
  }

  @media screen and (max-width: 899px) {
    & > .gatsby-image-wrapper {
      width: 100% !important;

      & img {
        object-fit: contain !important;
      }
    }

    .page-solutions & {
      width: calc(100% + 20px);
      margin-left: -10px;
      margin-right: -10px;
    }
  }
`

const Title = styled.div`
  font-size: 33px;
  line-height: 40px;
  margin-bottom: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};

  @media screen and (max-width: 899px) {
    font-size: 30px;
    text-align: center;

    .page-home & {
      text-align: left;
    }
  }
`

const Description = styled.div`
  &,
  & p {
    font-size: 20px;
    line-height: 33px;
  }

  & p:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 899px) {
    .page-home & {
      padding-right: 50px;
    }
  }
`
