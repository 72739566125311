import React from 'react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from '../../utils/getFluidGatsbyImage'
import { lang } from '../../config'

const FluidImage = ({ fields }) => {
  if (!fields) return ''

  const { file, title } = fields
  const image = {
    file: file[lang],
  }
  const fluidProps = getFluidGatsbyImage(image, { maxWidth: 720 })
  return <Img fluid={fluidProps} alt={title[lang]} loading="eager" />
}

export default FluidImage
