import React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import Button from './Button'
import { renderBlocks } from './'
import { lang } from '../../config'
import theme from '../../gatsby-plugin-theme-ui'

const backgroundPresets = {
  'Blue Logo': {
    background:
      '#032D6C url("/images/water-mark-logo.svg") no-repeat right top',
    backgroundSize: '623px 441px',
    backgroundSizeMobile: '428px 303px',
    headingColor: '#fff',
    descriptionColor: '#fff',
  },
  'Blue Waves': {
    background: '#000027 url("/images/bg-blue.jpg") no-repeat center center',
    backgroundSize: 'cover',
    backgroundSizeMobile: 'cover',
    headingColor: '#fff',
    descriptionColor: '#fff',
  },
  Gray: {
    background: theme.colors.tertiary,
  },
}

const Banner = ({ fields }) => {
  let {
    heading,
    description,
    button,
    button2,
    backgroundPreset,
    alignment,
  } = fields
  heading = heading && heading[lang]
  description = description && description[lang]
  button = button && button[lang].fields
  button2 = button2 && button2[lang].fields
  backgroundPreset = backgroundPreset && backgroundPreset[lang][0]
  alignment = alignment && alignment[lang][0]

  return (
    <Wrapper className="full-bleed" background={backgroundPreset}>
      <StyledContainer className="container">
        <Content className="content" alignment={alignment}>
          {heading && <Heading>{heading}</Heading>}
          {description && (
            <Description>{renderBlocks(description)}</Description>
          )}
          {(button || button2) && (
            <ButtonContainer>
              {button && <Button type="primary" data={button} />}
              {button2 && <Button type="primary" data={button2} />}
            </ButtonContainer>
          )}
        </Content>
      </StyledContainer>
    </Wrapper>
  )
}

export default Banner

const Heading = styled.h2`
  margin: 0 0 20px;
  font-size: 47px;
  line-height: 60px;
  font-weight: bold;
  padding-right: 30px;

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 30px;
    padding-right: 0;
  }
`

const Description = styled.div`
  font-size: 23px;
  line-height: 33px;
  margin-bottom: 30px;

  & p:last-child {
    margin-bottom: 0;
  }

  & .gatsby-image-wrapper {
    max-width: 100%;
    display: block !important;
    margin: 0 auto;
    margin-bottom: 2em;

    img {
      object-fit: contain !important;
    }
  }

  @media screen and (max-width: 899px) {
    margin-bottom: 0;

    & .gatsby-image-wrapper {
      height: auto !important;
      margin-bottom: 20px;

      img {
        height: auto !important;
        position: relative !important;
      }
    }
  }
`

const Wrapper = styled.section`
  background: ${props =>
    props.background && backgroundPresets[props.background].background
      ? backgroundPresets[props.background].background
      : 'transparent'};
  background-size: ${props =>
    props.background && backgroundPresets[props.background].backgroundSize
      ? backgroundPresets[props.background].backgroundSize
      : 'auto'};
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 72px 0;
  display: block;

  ${Heading} {
    color: ${props =>
      props.background
        ? backgroundPresets[props.background].headingColor
        : props.theme.colors.primary};
  }

  ${Description} {
    &,
    & p,
    & p a {
      color: ${props =>
        props.background
          ? backgroundPresets[props.background].descriptionColor
          : 'inherit'};
    }

    & p a:hover,
    & p a:focus {
      color: ${props => props.theme.colors.secondary};
    }
  }

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  @media screen and (max-width: 899px) {
    padding: 55px 0;
    background-size: ${props =>
      props.background &&
      backgroundPresets[props.background].backgroundSizeMobile
        ? backgroundPresets[props.background].backgroundSizeMobile
        : 'auto'};
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 0;

  * {
    z-index: 1;
  }

  @media screen and (max-width: 899px) {
    padding-top: 0;
  }
`

const Content = styled.div`
  width: 100%;
  text-align: ${props =>
    props.alignment ? props.alignment.toLowerCase() : 'inherit'};

  @media screen and (max-width: 899px) {
    text-align: left;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -25px;
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: 1em !important;

  & > * {
    margin: 25px;
  }

  @media screen and (max-width: 899px) {
    display: block;
    padding: 11px 0;
    margin-top: 5px;
    margin-bottom: -25px !important;

    & > * {
      margin: 55px 25px;

      &:first-child {
        margin-top: 25px;
      }

      &:last-child {
        margin-bottom: 25px;
      }
    }
  }
`
