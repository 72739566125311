import React, { useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from '@emotion/styled'
import Portal from './Portal'
import { CloseIcon } from '../../icons'

const Modal = ({
  children,
  showByDefault = false,
  activator,
  showClose,
  maxWidth = '1240px',
  className = '',
}) => {
  const [show, setShow] = useState(showByDefault)

  const content = (
    <Overlay onClick={() => setShow(false)}>
      <ModalContainer
        onClick={e => e.stopPropagation()}
        className={className}
        maxWidth={maxWidth}
      >
        {showClose && (
          <ModalClose type="button" onClick={() => setShow(false)}>
            <CloseIcon color="#707070" />
          </ModalClose>
        )}
        <ModalBody className="modal-body">{children}</ModalBody>
      </ModalContainer>
    </Overlay>
  )

  return (
    <>
      {activator && activator({ setShow })}
      <Portal>
        <CSSTransition
          in={show}
          timeout={120}
          classNames="modal-transition"
          unmountOnExit
        >
          {() => <div>{content}</div>}
        </CSSTransition>
      </Portal>
    </>
  )
}

export default Modal

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 15px;
`

const ModalContainer = styled.div`
  position: relative;
  z-index: 9999;
  width: 100%;
  max-width: ${props => props.maxWidth};
  max-height: 100%;
  margin: 0 auto;
  filter: drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.16));
  overflow-y: auto;
`

const ModalClose = styled.button`
  background: #fff;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 11px 16px;
  border-radius: 5px;
  line-height: 1;
  transition: 0.3s all;

  &:hover {
    background: ${props => props.theme.colors.secondary};
    transition: 0.3s all;
  }

  svg {
    width: 28px;
    display: block;
  }
`

const ModalBody = styled.div`
  padding: 115px 189px;
  border-radius: 5px;
  background-color: #fff;

  @media screen and (max-width: 899px) {
    padding: 37px;
  }
`
