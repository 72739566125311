import styled from '@emotion/styled'

const DropdownContainer = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  background: #fff;
  padding: 15px;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;

  &:hover {
    display: block;
  }
`

export default DropdownContainer
