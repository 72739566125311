import React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import { MailIcon, MessageIcon, PhoneIcon } from '../../icons'
import Modal from '../partials/Modal'
import ScheduleDemoForm from '../forms/ScheduleDemoForm'
import { renderBlocks } from './'
import { lang } from '../../config'

const ContactHeading = ({ title }) => {
  return (
    <StyledHeading>
      <Title>{title}</Title>
    </StyledHeading>
  )
}

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const Title = styled.h2`
  font-size: 33px;
  line-height: 40px;
  margin-bottom: 0;

  @media screen and (max-width: 899px) {
    text-align: left;
  }
`

const MaybeWrapWithLinkOrButton = ({ link, handleClick, children }) => {
  if (link && link.href) {
    return (
      <a
        href={link.href}
        title={link.title || ''}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
  }

  if (handleClick) {
    return <button onClick={handleClick}>{children}</button>
  }

  return <>{children}</>
}

const ContactColumn = ({
  icon,
  title,
  contents,
  link,
  handleClick,
  children,
}) => {
  return (
    <StyledContactColumn>
      <MaybeWrapWithLinkOrButton link={link} handleClick={handleClick}>
        <ColumnContainer>
          <Icon>{icon}</Icon>
          <ColumnText>
            <ContactHeading title={title} />
            <ColumnContents>{contents}</ColumnContents>
            {children}
          </ColumnText>
        </ColumnContainer>
      </MaybeWrapWithLinkOrButton>
    </StyledContactColumn>
  )
}

const Icon = styled.div`
  margin-right: 10px;
  padding: 5px;
  margin-left: -5px;
  border-radius: 50%;
  transition: 0.3s background-color;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    width: calc(100% - 8px);
  }

  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: 50%;
    margin-left: 0;
    bottom: 50%;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
    z-index: -1;
    border: 2px solid transparent;
  }
`

const StyledContactColumn = styled.div`
  padding: 15px 60px;
  flex-basis: 50%;

  & button {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &:first-of-type button {
    margin-right: 0;
  }

  &:last-of-type button {
    margin-left: 0;
  }

  &:last-of-type p {
    max-width: 240px;
  }

  & > a,
  & > button {
    cursor: pointer;

    &:hover {
      ${Icon} {
        & path:not(#Path_54) {
          fill: ${props => props.theme.colors.primary};
          transition: 0.3s fill;
        }

        &::before {
          border: 2px solid ${props => props.theme.colors.secondary};
          width: 100%;
          height: 100%;
          margin-left: -50%;
          bottom: 0;
        }
      }

      h2 {
        color: ${props => props.theme.colors.secondary};
      }
    }
  }

  & p {
    text-align: left;
  }

  & p:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (max-width: 899px) {
    margin: 30px 0;
    /* 
    &:first-of-type a {
      margin-left: 0;
    }

    &:last-of-type button {
      margin-left: 0;
    } */

    & p {
      text-align: left !important;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:last-of-type p {
      max-width: none;
    }
  }
`

const ColumnContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 899px) {
    justify-content: flex-start;
  }
`

const ColumnText = styled.div``

const ColumnContents = styled.div`
  font-size: 23px;
  line-height: 38px;
`

const ContactInfo = ({ fields }) => {
  let { phone, hours, email, contactFormModalText } = fields
  phone = phone && phone[lang]
  hours = hours && hours[lang]
  email = email && email[lang]
  contactFormModalText = contactFormModalText && contactFormModalText[lang]

  return (
    <Wrapper className="full-bleed">
      <StyledContainer className="container">
        <Content className="content">
          <ContactColumns>
            {phone && (
              <ContactColumn
                title="Call Us"
                icon={<PhoneIcon />}
                contents={phone}
                link={{
                  href: `tel:+1${phone}`,
                  title: 'Call Guardtime Federal',
                }}
              >
                {hours && <Hours>{renderBlocks(hours)}</Hours>}
              </ContactColumn>
            )}
            {email && (
              <ContactColumn
                title="Email Us"
                icon={<MailIcon />}
                contents={email}
                link={{
                  href: `mailto:${email}`,
                  title: 'Email Guardtime Federal',
                }}
              />
            )}
            {contactFormModalText && (
              <>
                <Modal
                  activator={({ setShow }) => (
                    <ContactColumn
                      title="Schedule a Demo"
                      icon={<MessageIcon />}
                      contents={renderBlocks(contactFormModalText)}
                      handleClick={() => setShow(true)}
                    />
                  )}
                >
                  <ModalH3>Schedule a Demo</ModalH3>
                  <ScheduleDemoForm />
                </Modal>
              </>
            )}
          </ContactColumns>
        </Content>
      </StyledContainer>
    </Wrapper>
  )
}

export default ContactInfo

const Wrapper = styled.section`
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 15px 0;
  display: block;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;

  * {
    z-index: 1;
  }
`

const Content = styled.div`
  width: 100%;
`

const ContactColumns = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 -10px;

  @media screen and (max-width: 899px) {
    display: block;
  }
`

const Hours = styled.div`
  margin-top: 6px;

  &,
  & p {
    font-size: 18px;
    line-height: 38px;
  }

  & p:last-child {
    margin-bottom: 0;
  }
`

const ModalH3 = styled.h3`
  color: #032d6c;
  font-size: 33px;
  line-height: 80px;
  margin-top: -10px;
`
