import React from 'react'
import styled from '@emotion/styled'

const MailIcon = ({
  color = 'inherit',
  width = '25.8',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25.801 19.35"
    {...props}
  >
    <path
      id="email-line"
      d="M26.133,5.833H3.557A1.612,1.612,0,0,0,1.945,7.445V23.571a1.612,1.612,0,0,0,1.612,1.612H26.133a1.612,1.612,0,0,0,1.612-1.612V7.446a1.612,1.612,0,0,0-1.612-1.612ZM24.892,23.572H4.9l5.644-5.838-1.161-1.12L3.557,22.636V8.672L13.58,18.645a1.612,1.612,0,0,0,2.274,0L26.133,8.421v14.11L20.2,16.6l-1.137,1.137ZM4.613,7.446H24.827L14.716,17.5Z"
      transform="translate(-1.945 -5.833)"
      fill="#f61d31"
    />
  </SVG>
)

export default MailIcon

const SVG = styled.svg`
  padding: 0;
`
