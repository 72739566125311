export const backgroundPresets = {
  Blue: {
    backgroundImage: {
      title: { 'en-US': 'Blue BG' },
      file: {
        'en-US': {
          url: '/images/bg-blue.jpg',
          contentType: 'image/jpeg',
          fileName: 'bg-blue.jpg',
          details: { image: { width: 1420, height: 926 }, size: 12245 },
        },
      },
    },
    headingColor: '#fff',
  },
}
