import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { Error, Select, SubmitButton } from './_fields'
import { ResultModal, SuccessIcon, FailureIcon } from './_partials'
import { endpoints } from '../../services/functions'

const contactFormEmailMessage = data => `You've received a new contact form submission from the Guardtime Federal website.

Name: ${data.name_first} ${data.name_last}
Email: ${data.email}
What can we help with? ${data.department}
Message: ${data.message}
`

const contactFormConfirmationEmailMessage = data => `Thank you for contacting Guardtime Federal. We've received the information you've submitted below, and someone from our team will be in touch soon.

Name: ${data.name_first} ${data.name_last}
Email: ${data.email}
What can we help with? ${data.department}
Message: ${data.message}
`

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm()
  const [submitting, setSubmitting] = useState()
  const [submitSuccess, setSubmitSuccess] = useState()
  const [submitFailure, setSubmitFailure] = useState()

  // Reset fields on submit success
  useEffect(() => {
    if (submitSuccess) {
      reset()
    }
  }, [submitSuccess])

  useEffect(() => {
    if (submitSuccess || submitFailure) {
      setSubmitting(false)
    }
  }, [submitSuccess, submitFailure])

  const onSubmit = data => {
    setSubmitting(true)

    // Reset submission statuses
    setSubmitSuccess()
    setSubmitFailure()

    const { name_first, name_last, email } = data

    const emailOptions = {
      from: 'GTF Website <info@guardtime-federal.com>',
      to: `${process.env.GATSBY_MAILGUN_CONTACT_DESTINATION}`,
      replyTo: `${name_first} ${name_last} <${email}>`,
      subject: 'Guardtime Federal Website Contact Submission',
      message: contactFormEmailMessage(data),
    }

    axios
      .post(endpoints.sendFormEmail, JSON.stringify(emailOptions))
      .then(response => {
        if (response.status !== 200) {
          console.error('There was an error sending this form.')
          console.log(response)
          setSubmitFailure(true)
        } else {
          console.log('Message sent successfully!')

          console.log('Sending confirmation email...')
          const confirmationEmailOptions = {
            from: 'Guardtime Federal <info@guardtime-federal.com>',
            to: `${name_first} ${name_last} <${email}>`,
            replyTo: 'Guardtime Federal <info@guardtime-federal.com>',
            subject: "We've Received Your Contact Form Submission",
            message: contactFormConfirmationEmailMessage(data),
          }
          axios
            .post(
              endpoints.sendFormEmail,
              JSON.stringify(confirmationEmailOptions)
            )
            .then(confirmationResponse => {
              if (confirmationResponse.status !== 200) {
                console.error(
                  'There was an error sending the confirmation email.'
                )
                console.log(confirmationResponse)
                setSubmitFailure(true)
              } else {
                console.log('Confirmation sent successfully!')
                setSubmitSuccess(true)
              }
            })
            .catch(confirmationErr => {
              console.error(
                'There was an error sending the confirmation email.'
              )
              console.log(confirmationErr)
              setSubmitFailure(true)
            })
        }
      })
      .catch(err => {
        console.error('There was an error sending this form.')
        console.log(err)
        setSubmitFailure(true)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Columns>
        <Field>
          <Label>
            First Name *
            <input
              type="text"
              {...register('name_first', {
                required: 'Please enter your first name',
              })}
            />
          </Label>
          <Error error={errors.name_first?.message} />
        </Field>
        <Field>
          <Label>
            Last Name *
            <input
              type="text"
              {...register('name_last', {
                required: 'Please enter your last name',
              })}
            />
          </Label>
          <Error error={errors.name_last?.message} />
        </Field>
      </Columns>
      <Columns>
        <Field>
          <Label>
            Email *
            <input
              type="email"
              {...register('email', {
                required: 'Please enter your email',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'Please enter a valid email',
                },
              })}
            />
          </Label>
          <Error error={errors.email?.message} />
        </Field>
        <Field>
          <Label>
            What can we help with? *
            <Select
              {...register('department', {
                required: 'Please select one',
              })}
              options={['Pricing', 'Sales']}
            />
          </Label>
          <Error error={errors.department?.message} />
        </Field>
      </Columns>
      <Field>
        <Label>
          Message *
          <textarea
            rows="4"
            cols="50"
            {...register('message', { required: 'Please enter a message' })}
          />
        </Label>
        <Error error={errors.message?.message} />
      </Field>

      <StyledSubmitButton
        message="Send Message"
        disabled={isSubmitting || submitting}
      />

      {submitSuccess && (
        <ResultModal showByDefault maxWidth="600px">
          <SuccessIcon />
          <h3>
            Message sent successfully!
            <br />
            We will get in touch with you shortly.
          </h3>
        </ResultModal>
      )}

      {submitFailure && (
        <ResultModal showByDefault maxWidth="600px">
          <FailureIcon />
          <h3>
            There was an error sending your message. Please review your details
            and try again.
          </h3>
        </ResultModal>
      )}
    </form>
  )
}

export default ContactForm

const Field = styled.div`
  width: auto;
  padding: 20px;
  margin: -20px;
  margin-top: 0;

  @media screen and (max-width: 899px) {
    padding: 15px;
    margin: -15px;
    margin-top: 0;

    & textarea {
      min-height: 369px;
    }
  }
`

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;

  & > ${Field} {
    width: 100%;
    margin: 0;
  }

  @media screen and (max-width: 899px) {
    display: block;
    margin: 0 -15px;
  }
`

const Label = styled.label`
  color: #fff;
  font-size: 23px;
  line-height: 38px;
  display: block;

  & input,
  & select,
  & textarea {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
`

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 50px;
`
