import React from 'react'
import styled from '@emotion/styled'

const LogoMark = ({
  color = '#fff',
  width = '200',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 200 220.971"
    {...props}
  >
    <g id="logo-mark" transform="translate(0)" opacity="0.05">
      <g id="Group_14" data-name="Group 14">
        <g id="Group_13" data-name="Group 13">
          <path
            id="Path_54"
            data-name="Path 54"
            d="M.057,27.03C29.627,7.2,65.784,1.189,100.647,0c34.4,1.457,70.01,7.5,99.2,27.045q.294,13.536.028,27.087A58.173,58.173,0,0,0,182.2,39.662c-2.19-1.344-4.325.792-6.206,1.669-18.9,11.471-38,22.617-56.937,34.018C112.85,78.857,106.966,83,100.493,86c-3.4-.537-6.318-2.588-9.351-4.088-13.129-6.818-26.13-13.89-39.3-20.623,7.147-3.975,14.181-8.161,21.047-12.631,9,4.031,17.383,9.321,26.356,13.41,8.888-4.131,16.9-10.03,25.5-14.8,9.969-6.28,20.7-11.415,30.1-18.572-33.657-8.954-69.49-9.067-103.456-1.754C32.661,31.74,12.961,38.6.03,53.863.016,44.923-.04,35.97.058,27.031Z"
            fill={color}
          />
          <path
            id="Path_55"
            data-name="Path 55"
            d="M.09,84.115C12.419,99,31,106.187,49.013,110.863a215.96,215.96,0,0,0,89.505,2.625c22.271-4.246,46-11.4,61.38-29.488.1,13.252.182,26.5-.029,39.742-38.616,22.833-84.885,28.613-128.7,23.565C46.331,144.137,21.757,136.765.09,123.742Q-.1,103.929.09,84.115Z"
            fill={color}
          />
          <path
            id="Path_56"
            data-name="Path 56"
            d="M.045,146.042c45.049,22.737,97.85,27.377,147.013,18a211.124,211.124,0,0,0,35.107-10.113c6.025-2.412,11.993-4.965,17.792-7.927.029,19.013.1,38.026-.042,57.039a340.668,340.668,0,0,1-57.859,14.772,314.039,314.039,0,0,1-56.814,2.9c-29.11-.916-57.9-7.306-85.113-17.6C-.11,184.1.059,165.069.045,146.042Z"
            fill={color}
          />
        </g>
      </g>
    </g>
  </SVG>
)

export default LogoMark

const SVG = styled.svg`
  padding: 0;
`
