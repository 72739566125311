import React from 'react'
import styled from '@emotion/styled'

const MenuIcon = ({
  color = '#000081',
  width = '27',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 27 18"
    {...props}
  >
    <g transform="translate(1.688 1.688)">
      <path
        data-name="Path 51"
        d="M6.188,10.688H29.813"
        transform="translate(-6.188 -10.688)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3.375"
      />
      <path
        data-name="Path 52"
        d="M6.188,18H29.813"
        transform="translate(-6.188 -10.688)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3.375"
      />
      <path
        data-name="Path 53"
        d="M6.188,25.313H29.813"
        transform="translate(-6.188 -10.688)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="3.375"
      />
    </g>
  </SVG>
)

export default MenuIcon

const SVG = styled.svg`
  padding: 0;
`
