import React from 'react'
import styled from '@emotion/styled'

const WarningIcon = ({
  color = 'inherit',
  width = '24px',
  height = '24px',
  ...props
}) => (
  <SVG
    baseProfile="tiny"
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    {...props}
  >
    <g>
      <path d="M12,5.511c0.561,0,1.119,0.354,1.544,1.062l5.912,9.854C20.307,17.842,19.65,19,18,19H6c-1.65,0-2.307-1.159-1.456-2.573   l5.912-9.854C10.881,5.865,11.439,5.511,12,5.511 M12,3.511c-1.296,0-2.482,0.74-3.259,2.031l-5.912,9.856   c-0.786,1.309-0.872,2.705-0.235,3.83S4.473,21,6,21h12c1.527,0,2.77-0.646,3.406-1.771s0.551-2.521-0.235-3.83l-5.912-9.854   C14.482,4.251,13.296,3.511,12,3.511z" />
    </g>
    <g>
      <circle cx="12" cy="16" r="1.3" />
    </g>
    <g>
      <path d="M13.5,10c0-0.83-0.671-1.5-1.5-1.5s-1.5,0.67-1.5,1.5c0,0.199,0.041,0.389,0.111,0.562C11.165,11.938,12,14,12,14   s0.835-2.062,1.391-3.438C13.459,10.389,13.5,10.199,13.5,10z" />
    </g>
  </SVG>
)

export default WarningIcon

const SVG = styled.svg`
  padding: 0;
`
