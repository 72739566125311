import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { ArrowIcon, DoubleArrowIcon } from '../../icons'
import DropdownContainer from '../partials/DropdownContainer'
import { renderBlocks } from './'
import { lang } from '../../config'

const StyledButton = ({ type = null, children, ...props }) => {
  switch (type) {
    case 'primary':
      return (
        <PrimaryLinkButton {...props}>
          {children}
          <ArrowIcon />
        </PrimaryLinkButton>
      )
    default:
      return <StyledLink {...props}>{children}</StyledLink>
  }
}

const Button = ({ data, icon = null, ...props }) => {
  if (!data) {
    return ''
  }

  const { url, pageLink, text, dropdown } = data

  const destination =
    (pageLink &&
      pageLink[lang] &&
      pageLink[lang] &&
      pageLink[lang].fields &&
      pageLink[lang].fields.slug &&
      pageLink[lang].fields.slug[lang] &&
      `/${pageLink[lang].fields.slug[lang]}`) ||
    (url && url[lang] && url[lang]) ||
    '#'

  const hasDropdown = dropdown && dropdown[lang]

  return (
    <ButtonContainer className="buttonContainer">
      <StyledButton
        to={destination}
        title={text && text[lang] ? text[lang] : ''}
        data-type="button"
        hasDropdown={hasDropdown}
        {...props}
      >
        {text && text[lang] ? text[lang] : ''}
        {icon && icon}
        {hasDropdown && <DoubleArrowIcon />}
      </StyledButton>
      {hasDropdown && (
        <DropdownContainer className="dropdown">
          <div className="dropdown-contents">
            {renderBlocks(dropdown[lang])}
          </div>
        </DropdownContainer>
      )}
    </ButtonContainer>
  )
}

export default Button

const ButtonContainer = styled.div`
  position: relative;
`

const StyledLink = styled(Link)`
  position: relative;

  &:hover + .dropdown,
  &:focus + .dropdown,
  &:focus-within + .dropdown {
    display: block;
  }

  &[href='/#']:hover,
  &[href='/#']:focus {
    color: ${props =>
      props.hasDropdown
        ? `${props.theme.colors.primary} !important`
        : 'inherit'};

      svg {
        fill: ${props =>
          props.hasDropdown
            ? `${props.theme.colors.primary} !important`
            : 'inherit'};
      }
  }

  &[href='/#']:active {
    pointer-events: none;
  }
`

const PrimaryLinkButton = styled(StyledLink)`
  background: ${props => props.theme.colors.secondary};
  padding: 11px 28px;
  border: 2px solid ${props => props.theme.colors.secondary};
  border-radius: 5px 31px 31px 5px;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;

  &,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: #fff;
  }

  svg {
    margin-left: 22px;
    width: 12px;
    fill: #fff;
    margin-bottom: -2px;
    transition: 0.3s all;
  }

  &:hover,
  &:focus {
    background: #fff;
    color: ${props => props.theme.colors.secondary};

    svg {
      fill: ${props => props.theme.colors.secondary};
      transition: 0.3s all;
    }
  }
`
