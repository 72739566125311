import React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import StaffMember from './StaffMember'
import { lang } from '../../config'

const StaffMembers = ({ fields }) => {
  let { heading, staffMembers } = fields
  heading = heading && heading[lang]
  staffMembers = staffMembers && staffMembers[lang]

  return (
    <Wrapper className="full-bleed">
      <StyledContainer className="container">
        <Content className="content">
          {heading && <Heading>{heading}</Heading>}
          {staffMembers && !!staffMembers.length && (
            <StaffMembersContainer>
              {staffMembers.map((card, idx) => (
                <StaffMember key={idx} data={card} />
              ))}
            </StaffMembersContainer>
          )}
        </Content>
      </StyledContainer>
    </Wrapper>
  )
}

export default StaffMembers

const Heading = styled.h2`
  margin: 0 0 20px;
  font-size: 47px;
  line-height: 80px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  padding-right: 30px;
  margin-top: -22px;

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
    margin-top: 0;
  }
`

const Wrapper = styled.section`
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 100px 0;
  display: block;

  & .content {
    background: transparent;
    position: relative;
    margin: 0 auto;
    padding: 0;
    padding-bottom: 0;
  }

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  @media screen and (max-width: 899px) {
    padding: 45px 0;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 2em;

  * {
    z-index: 1;
  }

  @media screen and (max-width: 899px) {
    padding-top: 0;
  }
`

const Content = styled.div`
  width: 100%;
  margin-bottom: 64px;
`

const StaffMembersContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -22px;
  margin-bottom: 23px;

  & > * {
    flex-basis: 33.333%;
  }

  @media screen and (max-width: 1248px) {
    & > * {
      flex-basis: 50%;
    }
  }

  @media screen and (max-width: 899px) {
    display: block;
    margin: 0 auto;

    & > * {
      margin: 0 -60px;
    }
  }
`
