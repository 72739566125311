import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { lang } from '../../config'

const FixedImage = ({ fields, animated = false }) => {
  if (!fields) return ''

  const image = fields.file[lang]
  let width = image.details.image.width
  let height = image.details.image.height

  let imageMaxWidth = 1190

  if (width > imageMaxWidth) {
    // That's a bit too wide, lets size her down
    height = Math.round((imageMaxWidth / width) * height)
    width = imageMaxWidth
  }

  // Animated SVG support
  if (image.contentType === 'image/svg+xml' && animated) {
    return (
      <SVGContainer
        style={{
          maxWidth: width,
          maxHeight: height,
        }}
      >
        <object type="image/svg+xml" data={image.url}></object>
      </SVGContainer>
    )
  }

  return (
    <Img
      fixed={{
        width: width,
        height: height,
        src: `${image.url}?w=${width}&h=${height}q=80&fit=fill`,
        srcSet: ` 
        ${image.url}?w=${width}&h=${height}&q=80&fit=fill 1x,
        ${image.url}?w=${Math.round(width * 1.5)}&h=${Math.round(
          height * 1.5
        )}&q=80&fit=fill 1.5x,
        ${image.url}?w=${width * 2}&h=${height * 2}&q=80&fit=fill 2x,
        ${image.url}?w=${width * 3}&h=${height * 3}&q=80&fit=fill 3x
    `,
      }}
      loading="eager"
    />
  )
}

export default FixedImage

const SVGContainer = styled.div`
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;

  & object {
    width: 100%;
    height: auto;
  }
`
