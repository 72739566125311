import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { Error, SubmitButton } from './_fields'
import { ResultModal, SuccessIcon, FailureIcon } from './_partials'
import { endpoints } from '../../services/functions'

const scheduleDemoFormEmailMessage = data => `You have received the following request to schedule a demo:

Name: ${data.name}
Email: ${data.email}
Job Title: ${data.job_title}
Organization: ${data.organization}
What Industry Are You In? ${data.industry}
Additional Notes or Questions: ${data.additional_notes_questions}
`

const scheduleDemoFormConfirmationEmailMessage = data => `Thank you for requesting a demo from Guardtime Federal. We've received the information you've submitted below, and someone from our team will be in touch soon.

Name: ${data.name}
Email: ${data.email}
Job Title: ${data.job_title}
Organization: ${data.organization}
What Industry Are You In? ${data.industry}
Additional Notes or Questions: ${data.additional_notes_questions}
`

const ScheduleDemoForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm()
  const [submitting, setSubmitting] = useState()
  const [submitSuccess, setSubmitSuccess] = useState()
  const [submitFailure, setSubmitFailure] = useState()

  // Reset fields on submit success
  useEffect(() => {
    if (submitSuccess) {
      reset()
    }
  }, [submitSuccess])

  useEffect(() => {
    if (submitSuccess || submitFailure) {
      setSubmitting(false)
    }
  }, [submitSuccess, submitFailure])

  const onSubmit = data => {
    setSubmitting(true)

    // Reset submission statuses
    setSubmitSuccess()
    setSubmitFailure()

    const { name, email } = data

    const emailOptions = {
      from: 'GTF Website <info@guardtime-federal.com>',
      to: `${process.env.GATSBY_MAILGUN_DEMO_DESTINATION}`,
      replyTo: `${name} <${email}>`,
      subject: 'Schedule a Demo Request',
      message: scheduleDemoFormEmailMessage(data),
    }

    axios
      .post(endpoints.sendFormEmail, JSON.stringify(emailOptions))
      .then(response => {
        if (response.status !== 200) {
          console.error('There was an error sending this form.')
          console.log(response)
          setSubmitFailure(true)
        } else {
          console.log('Message sent successfully!')

          console.log('Sending confirmation email...')
          const confirmationEmailOptions = {
            from: 'Guardtime Federal <info@guardtime-federal.com>',
            to: `${name} <${email}>`,
            replyTo: 'Guardtime Federal <info@guardtime-federal.com>',
            subject: "We've Received Your Schedule a Demo Request",
            message: scheduleDemoFormConfirmationEmailMessage(data),
          }
          axios
            .post(
              endpoints.sendFormEmail,
              JSON.stringify(confirmationEmailOptions)
            )
            .then(confirmationResponse => {
              if (confirmationResponse.status !== 200) {
                console.error(
                  'There was an error sending the confirmation email.'
                )
                console.log(confirmationResponse)
                setSubmitFailure(true)
              } else {
                console.log('Confirmation sent successfully!')
                setSubmitSuccess(true)
              }
            })
            .catch(confirmationErr => {
              console.error(
                'There was an error sending the confirmation email.'
              )
              console.log(confirmationErr)
              setSubmitFailure(true)
            })
        }
      })
      .catch(err => {
        console.error('There was an error sending this form.')
        console.log(err)
        setSubmitFailure(true)
      })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Field>
        <Label>
          Name: *
          <input
            type="text"
            {...register('name', { required: 'Please enter your name' })}
          />
        </Label>
        <Error error={errors.name?.message} />
      </Field>
      <Field>
        <Label>
          Email: *
          <input
            type="email"
            {...register('email', {
              required: 'Please enter your email',
              pattern: {
                value: /^\S+@\S+$/i,
                message: 'Please enter a valid email',
              },
            })}
          />
        </Label>
        <Error error={errors.email?.message} />
      </Field>
      <Field>
        <Label>
          Job Title: *
          <input
            type="text"
            {...register('job_title', {
              required: 'Please enter your job title',
            })}
          />
        </Label>
        <Error error={errors.job_title?.message} />
      </Field>
      <Field>
        <Label>
          Organization: *
          <input
            type="text"
            {...register('organization', {
              required: 'Please enter your organization',
            })}
          />
        </Label>
        <Error error={errors.organization?.message} />
      </Field>
      <Field>
        <Label>
          What Industry Are You In? *
          <input
            type="text"
            {...register('industry', {
              required: 'Please enter your industry',
            })}
          />
        </Label>
        <Error error={errors.industry?.message} />
      </Field>
      <Field>
        <Label>
          Additional Notes or Questions: *
          <textarea
            rows="4"
            cols="50"
            {...register('additional_notes_questions', {
              required: 'Please enter additional notes or questions',
            })}
          />
        </Label>
        <Error error={errors.additional_notes_questions?.message} />
      </Field>

      <StyledSubmitButton
        message="Submit Demo Request"
        disabled={isSubmitting || submitting}
      />

      {submitSuccess && (
        <ResultModal showByDefault maxWidth="600px">
          <SuccessIcon />
          <h3>
            Message sent successfully!
            <br />
            We will get in touch with you shortly.
          </h3>
        </ResultModal>
      )}

      {submitFailure && (
        <ResultModal showByDefault maxWidth="600px">
          <FailureIcon />
          <h3>
            There was an error sending your message. Please review your details
            and try again.
          </h3>
        </ResultModal>
      )}
    </form>
  )
}

export default ScheduleDemoForm

const Field = styled.div`
  width: auto;
  padding: 20px;
  margin: -20px;
  margin-top: 5px;
`

const Label = styled.label`
  color: #032d6c;
  font-size: 23px;
  line-height: 38px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;

  & input,
  & select,
  & textarea {
    display: block;
    width: 100%;
    margin-top: 10px;
    background: #f5f5f5;
    color: #032d6c;
    padding: 13px 25px;
  }
`

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 25px;
`
