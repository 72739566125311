import React from 'react'
import styled from '@emotion/styled'

const LinkedInIcon = ({
  color = 'inherit',
  width = '27px',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 27 25.809"
    {...props}
  >
    <path
      id="linkedin"
      d="M6.129,25.809V8.4H.341V25.809ZM3.236,6.016A3.018,3.018,0,1,0,3.274,0,3.016,3.016,0,1,0,3.2,6.016h.037Zm6.1,19.793H15.12V16.085a3.962,3.962,0,0,1,.19-1.412,3.168,3.168,0,0,1,2.97-2.117c2.094,0,2.932,1.6,2.932,3.938v9.316H27V15.824c0-5.349-2.856-7.837-6.664-7.837a5.764,5.764,0,0,0-5.255,2.934h.039V8.4H9.333c.076,1.634,0,17.413,0,17.413Z"
      fill={color}
    />
  </SVG>
)

export default LinkedInIcon

const SVG = styled.svg`
  padding: 0;
`
