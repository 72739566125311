import React from 'react'
import styled from '@emotion/styled'

const ArrowDownIcon = ({
  color = 'inherit',
  width = '16px',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 10"
    {...props}
  >
    >
    <path
      id="arrow-down"
      d="M8,10.5l6,6,6-6,2,2-8,8-8-8Z"
      transform="translate(-6 -10.5)"
      fill="#707070"
      fillRule="evenodd"
    />
  </SVG>
)

export default ArrowDownIcon

const SVG = styled.svg`
  padding: 0;
`
