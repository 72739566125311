import React from 'react'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'

const Video = ({ url }) => {
  return (
    <VideoContainer className="video">
      <PlayerWrapper>
        <ReactPlayer
          className="fluid-player"
          url={url}
          width="100%"
          height="100%"
        />
      </PlayerWrapper>
    </VideoContainer>
  )
}

export default Video

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
`

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 16:9 100 / (1280 / 720) */
  width: 100%;

  & .fluid-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`
