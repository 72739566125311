import React from 'react'
import styled from '@emotion/styled'

const PlusIcon = ({
  color = 'inherit',
  width = '33.718px',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 33.718 33.718"
    {...props}
  >
    <path
      id="Path_279"
      data-name="Path 279"
      d="M41.7,24.838H29.9V13.045a2.533,2.533,0,0,0-5.066,0V24.838H13.045a2.426,2.426,0,0,0-2.533,2.533A2.452,2.452,0,0,0,13.045,29.9H24.838V41.7a2.453,2.453,0,0,0,2.533,2.533A2.52,2.52,0,0,0,29.9,41.7V29.9H41.7a2.533,2.533,0,0,0,0-5.066Z"
      transform="translate(-10.512 -10.512)"
      fill={color}
    />
  </SVG>
)

export default PlusIcon

const SVG = styled.svg`
  padding: 0;
`
