import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import Container from '../Container'
import { BackgroundImage } from '../images'
import { ArrowFlatIcon } from '../../icons'
import { renderBlocks } from './'
import { lang } from '../../config'

const ProductBlock = ({ product }) => {
  if (!product) return ''

  let { name, shortDescription, slug } = product.fields
  name = name[lang]
  shortDescription = shortDescription[lang]
  slug = slug[lang]

  return (
    <ProductBlockWrapper>
      <ProductHeading>{name}</ProductHeading>
      <ProductDescription>{renderBlocks(shortDescription)}</ProductDescription>
      <ProductButton
        to={`/products/${slug}`}
        title={`Learn more about ${name}`}
      >
        <span className="title">Learn More</span>
        <span className="arrow-button">
          <ArrowFlatIcon color="#fff" />
        </span>
      </ProductButton>
    </ProductBlockWrapper>
  )
}

const ProductBlockWrapper = styled.div`
  margin: 13px;
  padding: 43px 90px;
  border-radius: 11px;
  background: #fff;

  @media screen and (max-width: 899px) {
    padding: 30px 38px;
    margin-top: 10px;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 10px;
    }
  }
`

const ProductHeading = styled.h3`
  margin: 0 0 8px;
  font-size: 33px;
  line-height: 50px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
  }
`

const ProductDescription = styled.div`
  font-size: 20px;
  line-height: 33px;
  font-weight: normal;
  margin-bottom: 30px;

  & > p:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 899px) {
    font-size: 20px;
    line-height: 33px;
  }
`

const ProductButton = styled(Link)`
  background: #fff;
  box-shadow: inset 0px 0px 0px 2px ${props => props.theme.colors.secondary};
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  overflow: hidden;
  transition: padding 0.2s ease, background-position 0.2s ease,
    transform 0.5s ease;

  & svg {
    width: 20px;
  }

  & span.arrow-button {
    background: ${props => props.theme.colors.secondary};
    height: 66px;
    width: 66px;
    border-radius: 50%;
    text-align: center;
  }

  & span.title {
    font-size: 23px;
    line-height: 38px;
    color: ${props => props.theme.colors.secondary};
    font-weight: bold;
    position: absolute;
    left: -118px;
    overflow: hidden;
    transform: scale(1, 1);
    white-space: nowrap;
  }

  &:hover {
    background-position: 5px center;
    padding-left: 157px;
    transform: scale(1, 1);

    & span.title {
      left: 25px;
    }
  }
`

const Products = ({ fields }) => {
  let { backgroundImage, heading, description, products } = fields
  backgroundImage = backgroundImage && backgroundImage[lang].fields
  heading = heading[lang]
  description = description[lang]
  products = products && products[lang]

  return (
    <Wrapper className="full-bleed">
      <StyledContainer>
        <BackgroundImage fields={backgroundImage} />
        <Content>
          <DescriptionContainer>
            <Heading>{heading}</Heading>
            <Description>{renderBlocks(description)}</Description>
          </DescriptionContainer>
          {products && products.length && (
            <ProductsContainer>
              {products.map((product, idx) => (
                <ProductBlock key={idx} product={product} />
              ))}
            </ProductsContainer>
          )}
        </Content>
      </StyledContainer>
    </Wrapper>
  )
}

export default Products

const Wrapper = styled.section`
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 30px 0;
  display: block;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  @media screen and (max-width: 899px) {
    padding: 0;
  }
`

const StyledContainer = styled(Container)`
  * {
    z-index: 1;
  }

  @media screen and (max-width: 899px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DescriptionContainer = styled.div`
  flex-basis: calc(100%) !important;
`

const Heading = styled.h2`
  margin: 0 0 26px;
  font-size: 47px;
  line-height: 80px;
  font-weight: bold;
  color: #fff;

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
  }
`

const Description = styled.div`
  font-size: 23px;
  line-height: 38px;
  flex-basis: 770px;
  margin-bottom: 15px;

  &,
  & > p {
    color: #fff;
  }

  & > p:first-of-type {
    margin-top: 0;
  }

  & > p:last-child {
    margin-bottom: 0;
  }
`

const ProductsContainer = styled.div`
  display: flex;
  margin: 0 -15px;

  & > * {
    flex-basis: calc(50% - 30px);
    flex-grow: 0;
    flex-shrink: 0;
  }

  @media screen and (max-width: 899px) {
    display: block;
  }
`
