import React from 'react'
import styled from '@emotion/styled'

const MessageIcon = ({
  color = 'inherit',
  width = '25.8px',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25.801 25.501"
    {...props}
  >
    <g id="bx-message-detail" transform="translate(-2.916 -2.916)">
      <path
        id="Path_57"
        data-name="Path 57"
        d="M26.137,2.917H5.5A2.583,2.583,0,0,0,2.916,5.5v15.48a2.583,2.583,0,0,0,2.58,2.58h3.87v4.86l8.1-4.861h8.673a2.583,2.583,0,0,0,2.58-2.58V5.5a2.583,2.583,0,0,0-2.58-2.58Zm0,18.06H16.75l-4.8,2.88V20.976H5.5V5.5H26.136v15.48Z"
        fill="#f61d31"
      />
      <path
        id="Path_58"
        data-name="Path 58"
        d="M10.208,10.208H24.791v2.917H10.208Zm0,5.833H20.416v2.917H10.208Z"
        transform="translate(-1.683 -1.209)"
        fill="#f61d31"
      />
    </g>
  </SVG>
)

export default MessageIcon

const SVG = styled.svg`
  padding: 0;
`
