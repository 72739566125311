import React from 'react'
import styled from '@emotion/styled'

const ArrowFlatIcon = ({
  color = 'inherit',
  width = '14px',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 13.757 22"
    {...props}
  >
    <path
      d="M13.257,6l11,11-11,11L10.5,25.25,18.757,17,10.5,8.75Z"
      transform="translate(-10.5 -6)"
      fill={color}
      fillRule="evenodd"
    />
  </SVG>
)

export default ArrowFlatIcon

const SVG = styled.svg`
  padding: 0;
`
