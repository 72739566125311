import React from 'react'
import styled from '@emotion/styled'

const CloseIcon = ({
  color = '#fff',
  width = '21.317',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 21.317 21.317"
    {...props}
  >
    <path
      data-name="close"
      d="M21.7,20.172a1.661,1.661,0,0,1-.5,1.22l-2.44,2.44a1.734,1.734,0,0,1-2.44,0l-5.275-5.276L5.767,23.832a1.661,1.661,0,0,1-1.22.5,1.661,1.661,0,0,1-1.22-.5l-2.44-2.44a1.734,1.734,0,0,1,0-2.44l5.275-5.275L.886,8.4a1.734,1.734,0,0,1,0-2.44l2.44-2.44a1.661,1.661,0,0,1,1.22-.5,1.661,1.661,0,0,1,1.22.5L11.042,8.8,16.318,3.52a1.734,1.734,0,0,1,2.44,0l2.44,2.44a1.734,1.734,0,0,1,0,2.44l-5.276,5.275L21.2,18.952a1.661,1.661,0,0,1,.5,1.22Z"
      transform="translate(-0.384 -3.018)"
      fill={color}
    />
  </SVG>
)

export default CloseIcon

const SVG = styled.svg`
  padding: 0;
`
