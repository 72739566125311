import React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import { BackgroundImage, FixedImage } from '../images'
import { renderBlocks } from './'
import { lang } from '../../config'
import theme from '../../gatsby-plugin-theme-ui'

const backgroundPresets = {
  Gray: theme.colors.tertiary,
}

const ImageAndText = ({ fields }) => {
  let {
    image,
    imagePosition,
    heading,
    description,
    backgroundPreset,
    backgroundImage,
    showTitleFirstOnMobile,
  } = fields
  image = image && image[lang].fields
  imagePosition = imagePosition && imagePosition[lang][0]
  heading = heading && heading[lang]
  description = description[lang]
  backgroundPreset = backgroundPreset && backgroundPreset[lang][0]
  backgroundImage = backgroundImage && backgroundImage[lang].fields
  showTitleFirstOnMobile =
    showTitleFirstOnMobile && showTitleFirstOnMobile[lang]

  return (
    <Wrapper className="full-bleed" background={backgroundPreset}>
      <StyledContainer className="container">
        <BackgroundImage fields={backgroundImage} />
        <Content
          className="content"
          hasBackgroundImage={backgroundImage}
          imagePosition={imagePosition}
        >
          <Text>
            {heading && (
              <Heading hideOnMobile={showTitleFirstOnMobile}>{heading}</Heading>
            )}
            <Description>{renderBlocks(description)}</Description>
          </Text>
          <Image>{image && <FixedImage fields={image} />}</Image>
          {showTitleFirstOnMobile && heading && (
            <MobileText>
              <MobileHeading>{heading}</MobileHeading>
            </MobileText>
          )}
        </Content>
      </StyledContainer>
    </Wrapper>
  )
}

export default ImageAndText

const Wrapper = styled.section`
  background: ${props =>
    props.background ? backgroundPresets[props.background] : 'transparent'};
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 64px 0;
  display: block;

  & .content {
    background: transparent;
    position: relative;
    margin: 0 auto;
  }

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  @media screen and (max-width: 899px) {
    padding: 55px 0;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 2em;

  * {
    z-index: 1;
  }

  @media screen and (max-width: 899px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Heading = styled.h3`
  margin: 0 0 20px;
  font-size: 47px;
  line-height: 60px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
    display: ${props => (props.hideOnMobile ? 'none' : 'inherit')};
  }
`

const Description = styled.div`
  font-size: 23px;
  line-height: 38px;
  flex-basis: 770px;
  flex-shrink: 0;

  & > p {
    margin-top: 15px;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > p:first-of-type {
    margin-top: 0;
  }

  & > h5,
  & > h6 {
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
  }

  & > h5 {
    font-size: 21px;
    line-height: 28px;
    text-transform: uppercase;
  }

  & > h6 {
    font-size: 18px;
    line-height: 28px;
  }

  & > hr {
    border: 1px solid rgba(3, 45, 108, 0.31);
    margin: 50px 0;
  }

  & a[data-type='button'] {
    border: 2px solid ${props => props.theme.colors.secondary};
    border-radius: 5px 25px 25px 5px;
    padding: 8px 20px;
    margin-top: 13px;
    display: inline-block;
    font-weight: bold;
    font-size: 23px;
    line-height: 33px;
    display: inline-flex;
    align-items: center;

    &,
    &:hover,
    &:focus,
    &:visited,
    &:active {
      color: ${props => props.theme.colors.secondary};
    }

    &:hover,
    &:focus {
      background: ${props => props.theme.colors.secondary};
      color: #fff;
    }

    &::after {
      content: '';
      background: ${props => props.theme.colors.secondary};
      mask-image: url('/images/arrow-icon.svg');
      display: inline-block;
      width: 9.53px;
      height: 16.67px;
      transition: 0.3s all;
      margin-left: 20px;
    }

    &:hover::after {
      background: #fff;
      transition: 0.3s all;
    }
  }

  & .buttonContainer {
    display: inline-block;
    margin-right: 50px;
    margin-top: 15px;

    & a {
      padding: 8px 40px;

      &,
      &:hover,
      &:focus,
      &:visited,
      &:active {
        background: ${props => props.theme.colors.secondary};
        color: #fff;

        &::after {
          background: #fff;
        }
      }

      &:hover,
      &:focus {
        background: #fff;
        color: ${props => props.theme.colors.secondary};

        &::after {
          background: ${props => props.theme.colors.secondary};
        }
      }
    }
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${props =>
    props.imagePosition === 'Left' ? 'row-reverse' : 'row'};
  margin-top: -20px;
  width: 100%;

  ${Heading} {
    color: ${props =>
      props.hasBackgroundImage ? '#fff' : props.theme.colors.primary};
  }

  ${Description} {
    &,
    & p {
      color: ${props =>
        props.hasBackgroundImage ? '#fff' : props.theme.colors.text};
    }
  }

  @media screen and (max-width: 899px) {
    flex-direction: column-reverse;
  }
`

const Image = styled.div`
  flex-grow: 0;
  flex-shrink: 0;

  .page-solutions & .gatsby-image-wrapper {
    max-width: 300px;
    max-height: 300px;

    img {
      position: relative !important;
      min-height: 300px;
      object-fit: contain !important;
      object-position: center center;
    }
  }

  @media screen and (max-width: 899px) {
    margin-bottom: 30px;

    & .gatsby-image-wrapper {
      height: auto !important;
      max-width: calc(100vw - 74px);

      img {
        position: relative !important;
        height: auto !important;
      }
    }
  }
`

const Text = styled.div`
  max-width: 740px;
`

const MobileText = styled(Text)`
  width: 100%;
  display: none;

  @media screen and (max-width: 899px) {
    display: block;
  }
`

const MobileHeading = styled(Heading)`
  text-align: left;
  margin-bottom: 30px;
`
