import React from 'react'
import styled from '@emotion/styled'

const DoubleArrowIcon = ({
  color = 'inherit',
  width = '14px',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 14.287 12.867"
    {...props}
  >
    <g transform="translate(-652 54)">
      <path
        id="arrow-ios-downward-outline"
        d="M14.631,20.788a1,1,0,0,1-.652-.239L7.866,15.342a1.058,1.058,0,0,1-.132-1.468,1,1,0,0,1,1.436-.135l5.46,4.665,5.46-4.5a1,1,0,0,1,1.436.156,1.056,1.056,0,0,1-.143,1.52l-6.112,5.029A1,1,0,0,1,14.631,20.788Z"
        transform="translate(644.502 -67.498)"
        fill={color}
      />
      <path
        id="arrow-ios-downward-outline-2"
        data-name="arrow-ios-downward-outline"
        d="M14.631,20.788a1,1,0,0,1-.652-.239L7.866,15.342a1.058,1.058,0,0,1-.132-1.468,1,1,0,0,1,1.436-.135l5.46,4.665,5.46-4.5a1,1,0,0,1,1.436.156,1.056,1.056,0,0,1-.143,1.52l-6.112,5.029A1,1,0,0,1,14.631,20.788Z"
        transform="translate(644.502 -61.923)"
        fill={color}
      />
    </g>
  </SVG>
)

export default DoubleArrowIcon

const SVG = styled.svg`
  padding: 0;
`
