import React from 'react'
import styled from '@emotion/styled'
import Container from '../Container'
import Card from './Card'
import Button from './Button'
import { BackgroundImage } from '../images'
import { lang } from '../../config'
import { backgroundPresets } from './_utils'

const Cards = ({ fields }) => {
  let { heading, cards, backgroundPreset, button } = fields
  heading = heading && heading[lang]
  cards = cards && cards[lang]
  backgroundPreset = backgroundPreset && backgroundPreset[lang][0]
  button = button && button[lang].fields

  return (
    <Wrapper className="full-bleed" background={backgroundPreset}>
      <StyledContainer className="container">
        <BackgroundImage
          fields={
            backgroundPresets[backgroundPreset] &&
            backgroundPresets[backgroundPreset].backgroundImage
              ? backgroundPresets[backgroundPreset].backgroundImage
              : null
          }
        />
        <Content className="content">
          {heading && <Heading>{heading}</Heading>}
          {cards && !!cards.length && (
            <CardsContainer>
              {cards.map((card, idx) => (
                <Card key={idx} data={card} />
              ))}
            </CardsContainer>
          )}
          {button && (
            <ButtonContainer>
              <Button type="primary" data={button} />
            </ButtonContainer>
          )}
        </Content>
      </StyledContainer>
    </Wrapper>
  )
}

export default Cards

const Heading = styled.h2`
  margin: 0 0 20px;
  font-size: 47px;
  line-height: 80px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  padding-right: 30px;
  margin-top: -22px;

  @media screen and (max-width: 899px) {
    font-size: 30px;
    line-height: 40px;
    margin-top: 0;
  }
`

const Wrapper = styled.section`
  position: relative;
  height: auto;
  overflow: hidden;
  padding: 90px 0;
  display: block;

  & .content {
    background: transparent;
    position: relative;
    margin: 0 auto;
    padding: 0;
    padding-bottom: 0;
  }

  ${Heading} {
    color: ${props =>
      props.background && backgroundPresets[props.background].headingColor
        ? backgroundPresets[props.background].headingColor
        : props.theme.colors.primary};
  }

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  @media screen and (max-width: 899px) {
    padding: 45px 0;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;

  * {
    z-index: 1;
  }
`

const Content = styled.div`
  width: 100%;
  margin-bottom: 64px;
`

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: -22px;

  & > * {
    flex-basis: 33.333%;
  }

  @media screen and (max-width: 899px) {
    display: block;
    margin: 0 -22px;
    width: calc(100% + 44px);
  }
`

const ButtonContainer = styled.div`
  padding: 11px 0;
  margin-top: 50px;

  @media screen and (max-width: 899px) {
    margin-top: 30px;
  }
`
