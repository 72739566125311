import React from 'react'
import styled from '@emotion/styled'

const Container = props => {
  return (
    <Wrapper className={`${props.className ? props.className : ''}`}>
      {props.children}
    </Wrapper>
  )
}

export default Container

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  max-width: calc(${props => props.theme.sizes.maxWidth} + 3em);
  padding: 3em 1.5em 2em;
  flex-grow: 1;

  @media screen and (max-width: 899px) {
    padding-left: 37px;
    padding-right: 37px;
  }
`
