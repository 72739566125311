import React from 'react'
import styled from '@emotion/styled'
import { ArrowIcon, ArrowDownIcon, LoadingIcon, WarningIcon } from '../../icons'

export const Error = ({ error }) => {
  if (!error) return ''
  return (
    <StyledError>
      <WarningIcon />
      {error}
    </StyledError>
  )
}

const StyledError = styled.span`
  color: ${props => props.theme.colors.secondary};
  display: flex;
  align-items: center;
  margin-top: 15px;

  svg {
    fill: ${props => props.theme.colors.secondary};
    margin-right: 6px;
  }
`

export const Select = React.forwardRef(({ options = [], ...props }, ref) => {
  return (
    <SelectWrapper>
      <select {...props} ref={ref}>
        {options.map((option, idx) => (
          <option key={idx} value={option}>
            {option}
          </option>
        ))}
      </select>
      <DownArrowContainer>
        <ArrowDownIcon />
      </DownArrowContainer>
    </SelectWrapper>
  )
})
Select.displayName = 'Select'

const SelectWrapper = styled.div`
  position: relative;

  & select {
    padding-right: 64px;
  }
`

const DownArrowContainer = styled.div`
  display: block;
  position: absolute;
  right: 1px;
  top: 7px;
  bottom: 7px;
  width: 44px;
  border-left: 1px solid #707070;
  pointer-events: none;
  text-align: center;
`

export const SubmitButton = ({ message = 'Submit', disabled, ...props }) => {
  return (
    <SubmitButtonContainer disabled={disabled} {...props}>
      {disabled ? (
        <LoadingIconContainer>
          <LoadingIcon />
        </LoadingIconContainer>
      ) : (
        <>
          {message}
          <ArrowIcon />
        </>
      )}
      <input type="submit" disabled={disabled} value={message} />
    </SubmitButtonContainer>
  )
}

const SubmitButtonContainer = styled.div`
  display: inline-block;
  position: relative;
  background: ${props => props.theme.colors.secondary};
  padding: 11px 28px;
  border: 2px solid ${props => props.theme.colors.secondary};
  border-radius: 5px 31px 31px 5px;
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  min-width: 244px;

  &,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: #fff;
  }

  svg {
    margin-left: 22px;
    width: 12px;
    fill: #fff;
    margin-bottom: -2px;
    transition: 0.3s all;
  }

  &:hover,
  &:focus {
    background: ${props =>
      props.disabled ? props.theme.colors.secondary : '#fff'};
    color: ${props => props.theme.colors.secondary};

    svg {
      fill: ${props => props.theme.colors.secondary};
      transition: 0.3s all;
    }
  }

  & input {
    background: transparent;
    padding: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: ${props => (props.disabled ? 'arrow' : 'pointer')};
    pointer-events: ${props => (props.disabled ? 'none' : 'inherit')};
  }

  & .loading-icon {
    margin: 0 auto;
    width: auto;
  }
`

const LoadingIconContainer = styled.div`
  text-align: center;
  margin-left: -12px;
`
