import React from 'react'
import styled from '@emotion/styled'

const PhoneIcon = ({
  color = 'inherit',
  width = '30px',
  height = '100%',
  ...props
}) => (
  <SVG
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 29.999 29.998"
    {...props}
  >
    <g id="bx-phone-call" transform="translate(-3 -3)">
      <path
        id="Path_54"
        data-name="Path 54"
        d="M24.878,30l3.009-3.007-3.879-3.879-1.939,1.939a1.486,1.486,0,0,1-1.368.407,15.231,15.231,0,0,1-6.753-3.406A15.231,15.231,0,0,1,10.542,15.3a1.5,1.5,0,0,1,.407-1.368l1.939-1.939L9.009,8.113,6,11.12a19.863,19.863,0,0,0,5.568,13.311A19.856,19.856,0,0,0,24.877,30Z"
        fill="none"
      />
      <path
        id="Path_55"
        data-name="Path 55"
        d="M24.849,33h.042A2.963,2.963,0,0,0,27,32.119l4.068-4.068a1.5,1.5,0,0,0,0-2.121l-6-6a1.5,1.5,0,0,0-2.121,0l-2.391,2.391a11.23,11.23,0,0,1-4.488-2.391,11.237,11.237,0,0,1-2.391-4.488l2.391-2.391a1.5,1.5,0,0,0,0-2.121l-6-6a1.545,1.545,0,0,0-2.121,0L3.879,9A3,3,0,0,0,3,11.15a22.958,22.958,0,0,0,6.447,15.4A22.947,22.947,0,0,0,24.849,33ZM9.008,8.112l3.879,3.879L10.948,13.93a1.494,1.494,0,0,0-.407,1.368,15.231,15.231,0,0,0,3.406,6.753A15.231,15.231,0,0,0,20.7,25.457a1.491,1.491,0,0,0,1.368-.407l1.939-1.939,3.879,3.879L24.877,30a19.859,19.859,0,0,1-13.309-5.568A19.859,19.859,0,0,1,6,11.12L9.007,8.113ZM30,16.5h3A13.21,13.21,0,0,0,19.484,3V6A10.162,10.162,0,0,1,30,16.5Z"
        fill="#f61d31"
      />
      <path
        id="Path_56"
        data-name="Path 56"
        d="M19.5,12c3.155,0,4.5,1.345,4.5,4.5h3C27,11.662,24.336,9,19.5,9Z"
        fill="#f61d31"
      />
    </g>
  </SVG>
)

export default PhoneIcon

const SVG = styled.svg`
  padding: 0;
`
