import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import Container from './Container'
import Logo from './Logo'
import { CloseIcon, DoubleArrowIcon, LogoMark, MenuIcon } from '../icons'
import DropdownContainer from './partials/DropdownContainer'

const Header = ({ simplePage = false }) => {
  const { menuLinks } = useSiteMetadata()

  const [navActive, setNavActive] = useState(false)

  return (
    <StyledHeader simplePage={simplePage}>
      <StyledContainer>
        <Main>
          <LogoContainer>
            <Link to="/" title="Home">
              <Logo />
            </Link>
          </LogoContainer>
          <MobileNavToggle
            onClick={() => setNavActive(prevNavActive => !prevNavActive)}
          >
            {navActive ? <CloseIcon /> : <MenuIcon />}
          </MobileNavToggle>
          <NavWrapper isActive={navActive}>
            <Menu>
              {menuLinks.map(link => {
                const hasSubMenu = link.subMenu && link.subMenu.length > 0
                return (
                  <MenuItem
                    key={link.name}
                    className={link.slug.replace('/', '')}
                  >
                    <MenuLink
                      to={link.disabled ? '#' : link.slug}
                      title={link.name}
                      activeClassName="active"
                      partiallyActive={link.slug === '/' ? false : true}
                      hasDropdown={hasSubMenu}
                    >
                      {link.name}
                    </MenuLink>
                    {hasSubMenu && (
                      <>
                        <DropdownArrow className="dropdown-arrow" />
                        <StyledDropdownContainer>
                          <ul aria-label="submenu">
                            {link.subMenu.map(subLink => (
                              <SubMenuItem key={subLink.name}>
                                <MenuLink
                                  to={subLink.link}
                                  title={subLink.name}
                                  activeClassName="active"
                                  partiallyActive
                                >
                                  {subLink.name}
                                </MenuLink>
                              </SubMenuItem>
                            ))}
                          </ul>
                        </StyledDropdownContainer>
                      </>
                    )}
                  </MenuItem>
                )
              })}
            </Menu>

            <MobileWaterMark />
          </NavWrapper>
        </Main>
      </StyledContainer>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;

  @media screen and (min-width: 900px) {
    position: ${props => (props.simplePage ? 'relative' : 'absolute')};
  }
`

const StyledContainer = styled(Container)`
  padding: 1.625em 1.5em 2.25em;
`

const Main = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const LogoContainer = styled.div`
  max-width: 220px;
  flex-shrink: 0;
`

const NavWrapper = styled.div`
  @media screen and (max-width: 899px) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: url('/images/bg-blue.jpg') no-repeat left bottom;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: ${props => (props.isActive ? 'flex' : 'none')};
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 9999999;
    background-size: cover;
  }
`

const Menu = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 899px) {
    display: block;
  }
`

const MenuItem = styled.li`
  display: inline-block;
  margin-left: 40px;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (max-width: 899px) {
    display: block;
    margin-left: 15px;
  }
`

const MenuLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    height: 3px;
    width: 100%;
    background: ${props => props.theme.colors.secondary};
    display: block;
    margin-top: -3px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transition: 0.3s all;
    transform-origin: left;
  }

  &,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    color: ${props => props.theme.colors.primary};
  }

  &:hover::after,
  &:focus::after,
  &.active::after {
    transform: scaleX(1);
    transition: 0.3s all;
  }

  &[href='/#']:hover,
  &[href='/#']:focus {
    color: ${props =>
      props.hasDropdown
        ? `${props.theme.colors.primary} !important`
        : 'inherit'};

    &::after {
      transform: scaleX(0);
    }
  }

  &[href='/#']:active {
    pointer-events: none;
  }

  @media screen and (max-width: 899px) {
    margin-bottom: 15px !important;
    font-size: 26px;
    line-height: 39px;

    &:last-child {
      margin-bottom: 0;
    }

    &,
    &:hover,
    &:focus,
    &:visited,
    &:active {
      color: #fff;
    }

    &[href='/#']:hover,
    &[href='/#']:focus {
      color: ${props => (props.hasDropdown ? `#fff !important` : 'inherit')};
    }
  }

  @media screen and (min-width: 900px) {
    .contact & {
      padding: 5px 42px;
      padding-bottom: 7px;
      border-radius: 5px 20px 20px 5px;
      border-bottom: 0px solid transparent;

      &::after {
        display: none;
      }

      &,
      &:hover,
      &:focus,
      &:visited,
      &:active,
      &.active {
        background: ${props => props.theme.colors.primary};
        color: #fff;
      }

      &:hover,
      &:focus,
      &.active {
        background: ${props => props.theme.colors.secondary};
      }
    }
  }
`

const DropdownArrow = styled(DoubleArrowIcon)`
  width: 20px;
  padding-left: 6px;
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;
  fill: ${props => props.theme.colors.primary};
  cursor: pointer;

  @media screen and (max-width: 899px) {
    fill: #fff;
  }
`

const StyledDropdownContainer = styled(DropdownContainer)`
  *:hover ~ &,
  *:focus ~ &,
  *:focus-within ~ & {
    display: block;
  }

  @media screen and (max-width: 899px) {
    display: block;
    background: transparent;
    border: none;
    position: relative;
    padding: 0;
  }
`

const SubMenuItem = styled(MenuItem)`
  margin-left: 0;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }
`

const MobileNavToggle = styled.button`
  display: none;

  @media screen and (max-width: 899px) {
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 9999999999;
    cursor: pointer;

    svg path {
      transition: 0.3s all;
    }

    &:hover {
      svg path {
        fill: ${props => props.theme.colors.secondary};
        stroke: ${props => props.theme.colors.secondary};
        transition: 0.3s all;
      }
    }
  }
`

const MobileWaterMark = styled(LogoMark)`
  flex-grow: 0;
  max-height: 305px;

  @media screen and (min-width: 900px) {
    display: none;
  }
`
