import Modal from '../partials/Modal'
import styled from '@emotion/styled'

export const ResultModal = styled(Modal)`
  & .modal-body {
    padding: 30px;
    text-align: center;
    line-height: 1.4;
  }
`

const ResultIcon = styled.div`
  width: 80px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  filter: invert(17%) sepia(82%) saturate(4026%) hue-rotate(345deg)
    brightness(98%) contrast(97%);
`

export const SuccessIcon = styled(ResultIcon)`
  background-image: url('/images/success-icon.svg');
`

export const FailureIcon = styled(ResultIcon)`
  background-image: url('/images/failure-icon.svg');
  background-size: 90px;
`
