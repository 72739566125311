import React from 'react'
import { useStaticQuery, Link } from 'gatsby'
import styled from '@emotion/styled'
import Container from './Container'
import { LinkedInIcon } from '../icons'
import { renderBlocks } from './blocks'

const Footer = () => {
  const { contentfulFooter: footer } = useStaticQuery(
    graphql`
      query {
        contentfulFooter {
          section1Heading
          section1Content {
            json
          }
          section2Heading
          section2Content {
            json
          }
          linkedInUrl
          copyrightText {
            json
          }
          privacyPolicyText
          privacyPolicyLink {
            slug
          }
          termsConditionsText
          termsConditionsLink {
            slug
          }
        }
      }
    `
  )

  if (!footer) {
    return ''
  }

  let {
    section1Heading,
    section1Content,
    section2Heading,
    section2Content,
    linkedInUrl,
    copyrightText,
    privacyPolicyText,
    privacyPolicyLink,
    termsConditionsText,
    termsConditionsLink,
  } = footer

  return (
    <Wrapper>
      <StyledContainer>
        <Row>
          <Section>
            {section1Heading && <Heading>{section1Heading}</Heading>}
            {renderBlocks(
              section1Content?.json || {
                content: [],
                data: {},
                nodeType: 'document',
              }
            )}
          </Section>
          <Section>
            {section2Heading && <Heading>{section2Heading}</Heading>}
            {renderBlocks(
              section2Content?.json || {
                content: [],
                data: {},
                nodeType: 'document',
              }
            )}
          </Section>
        </Row>
        <Divider />
        <SubfooterRow>
          <SubfooterSection>
            <SubfooterItem>
              <StyledLinkedInLink
                href={linkedInUrl}
                rel="nofollow noopener noreferrer"
                target="_blank"
                title="Guardtime Federal on LinkedIn"
              >
                <StyledLinkedInIcon />
              </StyledLinkedInLink>
            </SubfooterItem>
          </SubfooterSection>
          <SubfooterSection grow="1">
            <SubfooterItem grow="1">
              {renderBlocks(
                copyrightText?.json || {
                  content: [],
                  data: {},
                  nodeType: 'document',
                }
              )}
            </SubfooterItem>
            <SubfooterItem>
              {privacyPolicyLink && privacyPolicyLink.slug && (
                <Link to={`/${privacyPolicyLink.slug}`}>
                  {privacyPolicyText || 'Privacy Policy'}
                </Link>
              )}
            </SubfooterItem>
            <SubfooterItem>
              {termsConditionsLink && termsConditionsLink.slug && (
                <Link to={`/${termsConditionsLink.slug}`}>
                  {termsConditionsText || 'Terms and Conditions'}
                </Link>
              )}
            </SubfooterItem>
          </SubfooterSection>
        </SubfooterRow>
      </StyledContainer>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.footer`
  background: url('/images/bg-footer.jpg');
  background-size: cover;
  background-position: center center;
`

const StyledContainer = styled(Container)`
  @media screen and (max-width: 899px) {
    padding-bottom: 52px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 899px) {
    display: block;
  }
`

const Section = styled.div`
  padding: 49px 59px;

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;
  }

  ul {
    display: flex;
    flex-flow: row wrap;
  }

  li {
    display: inline-block;
    margin-left: 40px;

    &,
    & > p {
      font-size: 20px;
      line-height: 33px;
    }

    & > p {
      margin-bottom: 0;
    }

    &:first-of-type {
      margin-left: 0;
    }
  }

  & > p:empty:last-of-type {
    display: none;
  }

  @media screen and (max-width: 899px) {
    padding: 15px 70px !important;

    &:first-of-type {
      padding-top: 0 !important;
    }

    ul {
      display: inline-block;
    }

    li {
      display: block;
      margin-left: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
`

const Heading = styled.div`
  font-size: 26px;
  line-height: 38px;
  font-weight: 700;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 20px;

  @media screen and (max-width: 899px) {
    margin-bottom: 0;
  }
`

const Divider = styled.hr`
  border-style: none;
  border-top: 2px solid ${props => props.theme.colors.text};
  opacity: 0.11;
  margin-bottom: 25px;

  @media screen and (max-width: 899px) {
    border-top-color: #032d6c;
    opacity: 0.31;
  }
`

const SubfooterRow = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 899px) {
    align-items: flex-start;
  }
`

const SubfooterSection = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: ${props => (props.grow ? props.grow : '0')};

  @media screen and (max-width: 899px) {
    display: block;
  }
`

const SubfooterItem = styled.li`
  margin-left: 30px;
  opacity: 0.67;
  text-transform: uppercase;
  text-align: left;
  flex-grow: ${props => (props.grow ? props.grow : '0')};

  &,
  & > p {
    font-size: 14px;
    line-height: 2.21;
  }

  & > p {
    margin-bottom: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  a {
    text-decoration: none;

    &,
    &:hover,
    &:focus,
    &:visited,
    &:active {
      color: ${props => props.theme.colors.text};
    }

    &:hover,
    &:focus {
      color: ${props => props.theme.colors.secondary};
    }
  }

  svg {
    font-size: 28px;
  }

  @media screen and (max-width: 899px) {
    margin-left: 0;
    text-align: right;
  }
`

const StyledLinkedInLink = styled.a`
  margin-right: 20px;
`

const StyledLinkedInIcon = styled(LinkedInIcon)`
  opacity: 0.67;
`
